var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center guest" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c("b-col", { attrs: { md: "6" } }, [
              _c("div", { staticClass: "clearfix" }, [
                _c(
                  "h1",
                  { staticClass: "float-left display-3 mr-4 text-secondary" },
                  [_vm._v("Link Expire")]
                ),
                _c("h4", { staticClass: "pt-3 text-secondary" }, [
                  _vm._v("Oops! You're link expired.")
                ]),
                _c("br"),
                _c(
                  "p",
                  { staticClass: "text-muted h6" },
                  [
                    _vm._v(
                      "You take so much time to get here 🤷‍♂️, please make new reset request 😊\n            "
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "px-0",
                        attrs: { to: { name: "Forgot" }, variant: "link" }
                      },
                      [_vm._v("New Reset Password Request")]
                    )
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="app flex-row align-items-center guest">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4 text-secondary">Link Expire</h1>
            <h4 class="pt-3 text-secondary">Oops! You're link expired.</h4> <br>
            <p class="text-muted h6">You take so much time to get here 🤷‍♂️, please make new reset request 😊
              <b-button :to="{ name: 'Forgot' }" variant="link" class="px-0">New Reset Password Request</b-button>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetLinkExpired'
}
</script>
